import Image from 'next/legacy/image';
import Link from 'next/link';

import { Button } from '@jernia/shared/components/button/button';
import { ArrowRightIcon } from '@jernia/shared/components/icons/icons';

type ShortcutCard = {
  icon: string;
  title: string;
  description?: string;
  button: {
    label: string;
    href: string;
  };
};

export function ShortcutCard({ item }: { item: ShortcutCard }) {
  return (
    <Link
      href={item.button.href}
      className="p-2 md:p-10 bg-white flex flex-col items-center rounded md:mb-0"
    >
      {item?.icon ? (
        <div className="relative max-w-[96px] max-h-[96px] w-16 h-16 bg-primary-100 rounded-full p-3 md:w-24 md:h-24 md:bg-transparent">
          <Image
            className="w-full"
            src={item.icon}
            width="100"
            height="100"
            layout="responsive"
            alt={`Ikon for ${item.title}`}
          />
        </div>
      ) : null}
      <h1 className="text-base md:text-2xl text-center mt-2 md:mt-4 font-semibold text-primary break-all">
        {item.title}
      </h1>
      {item.description ? <p>{item.description}</p> : null}
      <Button
        className="w-full mt-4 hidden md:flex"
        rightIcon={<ArrowRightIcon />}
      >
        {item.button.label}
      </Button>
    </Link>
  );
}

export default function Shortcuts({ items }: { items: ShortcutCard[] }) {
  return (
    <nav className="grid grid-cols-2 gap-2 md:gap-8 py-6 md:py-14 m-auto h-full w-full max-w-5xl mx-auto">
      {items.map((item, i) => (
        <ShortcutCard key={`shortcut-${i}`} item={item} />
      ))}
    </nav>
  );
}
